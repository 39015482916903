exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-career-job-js": () => import("./../../../src/pages/career/job.js" /* webpackChunkName: "component---src-pages-career-job-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-estimate-app-cost-js": () => import("./../../../src/pages/estimate-app-cost.js" /* webpackChunkName: "component---src-pages-estimate-app-cost-js" */),
  "component---src-pages-eu-project-js": () => import("./../../../src/pages/eu-project.js" /* webpackChunkName: "component---src-pages-eu-project-js" */),
  "component---src-pages-gdpr-js": () => import("./../../../src/pages/gdpr.js" /* webpackChunkName: "component---src-pages-gdpr-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-methodology-js": () => import("./../../../src/pages/methodology.js" /* webpackChunkName: "component---src-pages-methodology-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-job-js": () => import("./../../../src/templates/Job.js" /* webpackChunkName: "component---src-templates-job-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/Project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-service-by-customer-type-js": () => import("./../../../src/templates/ServiceByCustomerType.js" /* webpackChunkName: "component---src-templates-service-by-customer-type-js" */),
  "component---src-templates-service-by-industry-js": () => import("./../../../src/templates/ServiceByIndustry.js" /* webpackChunkName: "component---src-templates-service-by-industry-js" */),
  "component---src-templates-service-by-technology-js": () => import("./../../../src/templates/ServiceByTechnology.js" /* webpackChunkName: "component---src-templates-service-by-technology-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/Service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

